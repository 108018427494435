exports.components = {
  "component---src-pages-404-index-en-js": () => import("./../../../src/pages/404/index.en.js" /* webpackChunkName: "component---src-pages-404-index-en-js" */),
  "component---src-pages-404-index-es-js": () => import("./../../../src/pages/404/index.es.js" /* webpackChunkName: "component---src-pages-404-index-es-js" */),
  "component---src-pages-about-index-en-js": () => import("./../../../src/pages/about/index.en.js" /* webpackChunkName: "component---src-pages-about-index-en-js" */),
  "component---src-pages-about-index-es-js": () => import("./../../../src/pages/about/index.es.js" /* webpackChunkName: "component---src-pages-about-index-es-js" */),
  "component---src-pages-contact-index-en-js": () => import("./../../../src/pages/contact/index.en.js" /* webpackChunkName: "component---src-pages-contact-index-en-js" */),
  "component---src-pages-contact-index-es-js": () => import("./../../../src/pages/contact/index.es.js" /* webpackChunkName: "component---src-pages-contact-index-es-js" */),
  "component---src-pages-faq-index-en-js": () => import("./../../../src/pages/faq/index.en.js" /* webpackChunkName: "component---src-pages-faq-index-en-js" */),
  "component---src-pages-faq-index-es-js": () => import("./../../../src/pages/faq/index.es.js" /* webpackChunkName: "component---src-pages-faq-index-es-js" */),
  "component---src-pages-impact-index-en-js": () => import("./../../../src/pages/impact/index.en.js" /* webpackChunkName: "component---src-pages-impact-index-en-js" */),
  "component---src-pages-impact-index-es-js": () => import("./../../../src/pages/impact/index.es.js" /* webpackChunkName: "component---src-pages-impact-index-es-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index/en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index/es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-en-js": () => import("./../../../src/pages/news/index.en.js" /* webpackChunkName: "component---src-pages-news-index-en-js" */),
  "component---src-pages-news-index-es-js": () => import("./../../../src/pages/news/index.es.js" /* webpackChunkName: "component---src-pages-news-index-es-js" */),
  "component---src-pages-privacy-index-en-js": () => import("./../../../src/pages/privacy/index.en.js" /* webpackChunkName: "component---src-pages-privacy-index-en-js" */),
  "component---src-pages-privacy-index-es-js": () => import("./../../../src/pages/privacy/index.es.js" /* webpackChunkName: "component---src-pages-privacy-index-es-js" */),
  "component---src-pages-qr-index-en-js": () => import("./../../../src/pages/qr/index.en.js" /* webpackChunkName: "component---src-pages-qr-index-en-js" */),
  "component---src-pages-qr-js": () => import("./../../../src/pages/qr.js" /* webpackChunkName: "component---src-pages-qr-js" */),
  "component---src-pages-save-contact-index-en-js": () => import("./../../../src/pages/saveContact/index.en.js" /* webpackChunkName: "component---src-pages-save-contact-index-en-js" */),
  "component---src-pages-save-contact-index-es-js": () => import("./../../../src/pages/saveContact/index.es.js" /* webpackChunkName: "component---src-pages-save-contact-index-es-js" */),
  "component---src-pages-terms-index-en-js": () => import("./../../../src/pages/terms/index.en.js" /* webpackChunkName: "component---src-pages-terms-index-en-js" */),
  "component---src-pages-terms-index-es-js": () => import("./../../../src/pages/terms/index.es.js" /* webpackChunkName: "component---src-pages-terms-index-es-js" */),
  "component---src-templates-article-post-index-jsx": () => import("./../../../src/templates/ArticlePost/Index.jsx" /* webpackChunkName: "component---src-templates-article-post-index-jsx" */)
}

