import React, { createContext, useEffect, useState } from "react";
import { navigate } from "gatsby";
export const AppContext = createContext();
export const useAppContext = () => React.useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [locale, setLocale] = useState(() => {
    let localeFromStorage = "en";
    if (typeof window != "undefined") {
      localeFromStorage = localStorage.getItem("locale");
    }
    return localeFromStorage != "" ? localeFromStorage : "en";
  });
  const [path, setPath] = useState(() => {
    let currentPath = "/";
    if (typeof window != "undefined") {
      currentPath = localStorage.getItem("currentPath");
    }
    return currentPath || "/";
  });
  useEffect(() => {
    const localeFromStorage = localStorage.getItem("locale");
    const originalPath = window.location.pathname;

    if (localeFromStorage) {
      setLocale(localeFromStorage);
    } else {
      if (originalPath.includes("es")) {
        setLocale("es");
        localStorage.setItem("locale", "es");
      } else {
        localStorage.setItem("locale", "en");
      }
    }
  }, []);

  useEffect(() => {
    changeRoute(locale);
  }, [locale]);

  async function changeRoute(language, currentPath) {
    // let newPath = path;
    if (currentPath) {
      console.log("path from window in change route: ", currentPath);
    }
    let newPath = currentPath ? currentPath : path;

    if (language) {
      if (language.includes("en")) {
        newPath = path.replace("/es", "/en");
      }
      if (language.includes("es")) {
        newPath = path.replace("/en", "/es");
        if (newPath == "/") {
          newPath = "/es";
        }
      }
    }
    await navigate(newPath);
  }

  const handleLocale = (language, currentPath) => {
    setLocale(language);
    localStorage.setItem("locale", language);
    changeRoute(language, currentPath);
  };

  const handlePath = (currentPath) => {
    setPath(currentPath);
    localStorage.setItem("currentPath", currentPath);
  };

  return (
    <AppContext.Provider
      value={{
        locale,
        handleLocale,
        path,
        handlePath,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
